// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bookmein-js": () => import("./../../../src/pages/bookmein.js" /* webpackChunkName: "component---src-pages-bookmein-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-filmswithfriends-js": () => import("./../../../src/pages/filmswithfriends.js" /* webpackChunkName: "component---src-pages-filmswithfriends-js" */),
  "component---src-pages-filmswithfriends-react-native-js": () => import("./../../../src/pages/filmswithfriends-react-native.js" /* webpackChunkName: "component---src-pages-filmswithfriends-react-native-js" */),
  "component---src-pages-gabdev-js": () => import("./../../../src/pages/gabdev.js" /* webpackChunkName: "component---src-pages-gabdev-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tic-tac-trivia-js": () => import("./../../../src/pages/tic-tac-trivia.js" /* webpackChunkName: "component---src-pages-tic-tac-trivia-js" */),
  "component---src-pages-wayfarer-js": () => import("./../../../src/pages/wayfarer.js" /* webpackChunkName: "component---src-pages-wayfarer-js" */)
}

